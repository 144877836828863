
import {
  Component, Prop, Vue, Watch,
} from 'nuxt-property-decorator'
import { Flicking, FlickingOptions } from '@egjs/vue-flicking'
import tailwindConfig from '../tailwind.config.js'
import IImageVideoContent from '../shared/general/interfaces/IImageVideoContent'
import { openLightbox, openLightboxCarousel } from '../shared/general/services/LightboxService'
import { IGalleryContent } from '../shared/general/interfaces/IGalleryContent'
import BaseIconButton from './base/BaseIconButton.vue'
import BaseHeadline from './base/BaseHeadline.vue'

@Component({
  name: 'Gallery',
  components: {
    Flicking,
    BaseIconButton,
    BaseHeadline,
    BaseImageVideo: () => import('./ImageVideo/BaseImageVideo.vue'),
  },
})
export default class Gallery extends Vue {
  @Prop() headline ?: string

  @Prop({ required: true }) elements! : IGalleryContent[]

  @Prop({ default: false }) lightbox! : boolean

  $refs! : {
    'control-left' ?: Vue
    'control-right' ?: Vue
    carousel ?: Flicking
  }

  private sliderVisible : boolean = true

  private get flickingOptions () : FlickingOptions {
    return {
      horizontal: true,
      circularFallback: 'linear',
      circular: true,
      panelsPerView: this.panelsPerView,
      align: 'prev',
    } as FlickingOptions
  }

  private animating : boolean = false

  private panelsPerView : number = 3

  private mutationObserver ?: MutationObserver

  private resizeObserver ?: ResizeObserver

  private showArrows : boolean = true

  private slide (direction : 'prev' | 'next') : void {
    if (!direction || this.animating) return

    this.animating = true
    this.$refs.carousel?.[direction]()
  }

  private checkMobile () : void {
    this.setVisiblePanels()

    window.addEventListener('resize', () => {
      this.setVisiblePanels()
    })
  }

  private setVisiblePanels () : void {
    const currentAmount = this.panelsPerView
    this.panelsPerView = window.innerWidth < +tailwindConfig.theme.screens.sm.replace('px', '') ? 1 : 3

    if (currentAmount !== this.panelsPerView) {
      // hide & show the slider to force a rerender
      this.sliderVisible = false

      this.$nextTick(() => {
        this.sliderVisible = true
      })
    }
  }

  private async observeImageHeight () : Promise<void> {
    this.resizeObserver?.disconnect()
    const image = this.$el.querySelector<HTMLImageElement>('.gallery-element:first-child figure > section img')

    // we have to wait for the image actually being there, otherwise we can't proceed
    if (!image) {
      if (this.mutationObserver) return

      this.mutationObserver = new MutationObserver(this.observeImageHeight)
      this.mutationObserver.observe(this.$el, { subtree: true, childList: true })
      return
    }

    this.mutationObserver?.disconnect()
    this.mutationObserver = undefined

    // wait for the image to actually have a height before trying to set the button positions
    if (!image.complete && image.naturalHeight === 0) {
      await new Promise<void>((resolve) => {
        if (!image) return

        image.onload = () => {
          resolve()
        }
      })
    }

    this.resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect.height <= 0) return
      document.documentElement.style.setProperty(
        '--gallery-button-top',
        `${entries[0].contentRect.height / 2}px`,
      )

      // show the controls once they are positioned
      this.$refs['control-left']?.$el?.classList.remove('hidden')
      this.$refs['control-right']?.$el?.classList.remove('hidden')
    })

    this.resizeObserver.observe(image)
  }

  private showLightboxCarousel (
    content : IImageVideoContent,
    mediaType : 'image' | 'video',
    startIndex : number,
  ) : void {
    if (!this.lightbox && mediaType === 'video') {
      openLightbox(content)
      return
    }

    if (!this.lightbox) return

    openLightboxCarousel(this.elements, startIndex)
  }

  @Watch('panelsPerView')
  private onSlidesToShowChange () : void {
    this.observeImageHeight()
    this.showArrows = (this.$refs.carousel?.$children.length || 0) > this.panelsPerView
  }

  mounted () {
    this.checkMobile()
    this.observeImageHeight()
    this.showArrows = (this.$refs.carousel?.$children.length || 0) > this.panelsPerView
  }

  beforeUnmount () {
    this.mutationObserver?.disconnect()
    this.resizeObserver?.disconnect()
  }
}
